.scroll-to-top {
    cursor: pointer;
    margin-top: 4rem;
    display: inline-block;
}

.scroll-to-top:hover {
    opacity: 1;
}

.scroll-to-top div {
    display: block;
}