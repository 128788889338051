.section__splash-landing {
    height: 100vh;
    width: 100vw;
    position: relative;

    .logo-tagline-container {
        position: absolute;
        top: 100px;
        left: calc(12vw - 80px);
    }

    h3 {
        margin-left: 30px;
        opacity: 0;
    }

    .company-logo {
        max-width: 60vw;
    }

    .banner-image-container {
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        img {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            z-index: -1;
        }
    }

    .splash-poster-mobile {
        display: none;
    }

    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .splash-image {
        min-height: 100%;
        min-width: 100%;
        position: fixed;
        top: 0;
    }

    .seo-title {
        opacity: 0.01;
        margin: 0;
    }

    .ai-text {
        animation: slide-right 1s ease forwards;
        animation-delay: 1s;
    }

    .people-text {
        animation: slide-right 1s ease forwards;
        animation-delay: 2s
    }

    @media screen and (max-width: 900px) {
        h3 {
            font-size: 1.5rem;
            margin-left: 25px;
        }

        .logo-tagline-container {
            top: 100px;
            left: 50px;
        }

        .splash-poster {
            display: none;
        }

        .splash-poster-mobile {
            display: inline-block;
            object-position: bottom;
            object-position: right;
        }
    }

    @media screen and (max-width: 600px) {
        h3 br {
            display: block;
        }

        .tagline-container {
            bottom: 20vh;
        }

        .banner-image-container {
            img {
                left: auto;
                right: 0;
            }
        }

        .logo-tagline-container {
            top: 50px;
            left: 20px;
        }
    }
}

.section__global-expertise {
    padding-bottom: 2rem;

    .expertise-copy,
    h2 {
        flex: .5;
    }

    .product-icon {
        max-width: 125px;
        margin: 2rem auto 1rem auto;
    }

    .products-section {
        padding-top: 4rem;
    }

    .product-column {
        margin-top: 0;
    }

    .product-column:nth-child(2) .product-icon {
        max-width: 75px;
        margin: 3.5rem auto 2.75rem auto;
    }

    .section--image {
        margin-bottom: 0;
    }

    .highlighted-use-case {
        max-height: 0px;
        padding: 0;
        margin-top: 0;
        overflow: hidden;
        transition: all 1s;
        opacity: 0;

        .use-case-title {
            display: block;
            font-size: 1.5rem;
            padding-bottom: 0;
        }

        .use-case-example {
            display: block;
            margin-top: 1rem;
            text-transform: uppercase;
        }

        h3 {
            font-size: 2rem;
            margin: 0 0 2rem 0;
        }

        p {
            margin: 0;
        }

        a {
            margin-bottom: 3rem;
        }

        &.show-highlighted {
            max-height: 1999px;
            opacity: 1;
            padding: 3rem 0;
            margin-top: 5rem;
        }

        &__container {
            display: flex;

            img {
                max-width: 300px;
                margin-left: 10%;
                object-fit: cover;
                order: 2;
            }
        }
    }

    @media screen and (max-width: 900px) {
        br {
            display: none;
        }

        a {
            margin-top: 1rem;
        }

        .product-icon {
            max-width: 125px;
            margin: 2rem auto 0 auto !important;
        }

        .product-column {
            margin-bottom: 5rem;

            &:not(:last-child) {
                padding-right: 0;
            }

            &:nth-child(2) .product-icon {
                margin: 1rem 0;
            }
        }

        .highlighted-use-case {
            border-top: none;

            &__container {
                display: block;

                img {
                    max-width: none;
                    max-height: 50vh;
                    margin: 0 0 2rem 0;
                    display: block;
                }
            }
        }
    }
}

.section__image-generation-demo {
    margin-bottom: 3rem;
    select {
        width: 200px;
        max-width: calc(100vw - 12rem);
        position: absolute;
        left: 11rem;
        background: black;
        border: none;
        border-bottom: 1px solid white;
        padding-bottom: 4px;
        transform: translateY(4px);
        text-transform: uppercase;
        border-radius: 0;
        font-size: 1rem;
        color: white;
    }

    option {
        text-transform: none !important;
    }

    form {
        margin-bottom: 3rem;

        >div:not(.poses) {
            margin-top: 2rem;
            position: relative;
            text-align: right;
            width: 10rem;
        }

        .poses {
            margin-top: 2rem;
        }
    }

    .loader-image {
        color: white;
        width: 100%;
        margin-top: 50%;

        img {
            filter: invert(1);
        }

    }

    .resulting-image {
        margin: 0 auto;
        max-width: 512px;
    }

    .controls-column {
        width: 500px;
    }

    .image-results-column {
        padding-top: 2rem;
        height: 768px;
        text-align: center;
        transition: all 1s;
    }

    .poses-container {
        text-align: center;
    }

    .pose-option {
        display: inline-block;
        width: 104px;
        margin: 1rem;
        position: relative;

        img {
            width: 100px;
            border: 2px solid #fff;
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            width: calc(100% + 4px);
            height: 100%;
            opacity: 0.8;
            transition: opacity .5s;
            background-color: black;
            appearance: none;
            border-radius: 0;
            overflow: show;

            &:hover {
                cursor: pointer;
            }

            &:checked {
                background-color: transparent;
            }
        }
    }

    @media screen and (max-width: 900px) {
        .controls-column {
            width: 100%;
        }

        .resulting-image {
            max-width: 100%;
        }

        .image-results-column {
            padding: 5rem 0 2rem 0;
            height: auto;
        }

        .loader-image {
            margin: 50vw auto;
        }

        button {
            // margin: auto;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }

        form>div:not(.poses) {
            width: 5rem;
        }

        select {
            left: 6rem;
        }
    }
}

.section__human-element {
    margin-top: -4rem;

    .section--container {
        margin-bottom: 0;
    }

    img {
        object-fit: contain;
        max-width: 30vw;
        position: relative;
        z-index: 1;
        margin-top: 3rem;
    }

    h3 {
        margin-top: 3rem;
    }

    ul {
        padding-inline-start: 1.5rem;
    }

    @media screen and (max-width: 900px) {
        div.container__flex {
            display: flex;
            flex-direction: column;
        }

        img {
            max-width: 500px;
            width: 100%;
            margin-bottom: -1rem;
        }

    }

    // @media screen and (max-width: 500px) {
    //     img {
    //         margin-bottom: 3rem;
    //     }
    // }
}

.section__contact-form {
    background: white;
    color: black;
    text-align: left;

    a {
        color: black;

        &:after {
            background-color: black;
        }
    }

    form {
        display: flex;
        text-align: left;
        gap: 4rem;

        > div {
            width: 50%;
        }
    }

    .section--container {
        margin: 4rem 0;
        border: none;
    }

    label {
        display: block;
        text-transform: uppercase;
        font-size: 1.25rem;
        letter-spacing: 2px;
        margin-bottom: 3rem;
    }

    h2 {
        padding-bottom: 2rem;
        max-width: 1000px;
        margin: auto;
    }

    input {
        width: 500px;
        max-width: 100%;
        border-bottom: 2px solid black;
        margin: 5rem auto 1rem auto;
        color: black;
        font-size: 2rem;
        padding: 0;
        border-radius: 0;
    }

    textarea {
        margin: 5rem auto 1rem auto;
        width: 100%;
        height: 15rem;
        padding: 1rem;
        background: rgba(0, 0, 0, 0.2);
        border: none;
        font-size: 1rem;
        font-family: 'Roboto', sans-serif;
    }

    button {
        margin-top: 3rem;
        background: black;
        color: white;
        transition: all .5s ease;

        &:hover {
            color: black;
        }
    }

    a {
        margin-top: 5rem;
    }

    @media screen and (max-width: 500px) {
        form {
            display: block;
            text-align: center;

            > div {
                width: 100%;
            }
        }

        input {
            font-size: 1.5rem;
        }

        textarea {
            width: calc(100% - 2rem);
            border-radius: 0;
        }
    }

}

.section__who-we-are {
    .profile-photo-container {
        text-align: center;

        p,
        h3 {
            margin: 0;
            font-size: 1rem;
        }

        h3 {
            font-size: 1.5rem;
        }

        >div {
            display: inline-block;
            width: 180px;
            padding: 0 2vw;
            margin: 0 auto;
        }

        img {
            margin-bottom: 1rem;
        }
    }

    img {
        max-width: 100%;
        border-radius: 1000px;
        margin: 0 auto;
    }

    p {
        margin-top: 0;
    }

    ul {
        margin-top: 1rem;
    }

    .experience-texts {
        gap: 3rem;
        margin-top: 3rem;
    }

    .experience-summary {
        margin-top: 10px;
        font-size: 2rem;
        line-height: 3rem;
        flex: 1;
    }

    .experience-bullets {
        border-left: 2px solid $black;
        flex: 1;
    }

    @media screen and (max-width: 900px) {
        .profile-photo-container>div {
            display: block;
            margin-bottom: 3rem;
            width: 200px;
        }
    }
}

.section__companies {
    text-align: center;
    padding-top: 0;

    h2 {
        font-size: 2rem;
        display: inline-block;
        padding-bottom: 1rem;
    }

    .section--container {
        border-top: none;
        margin-top: 0;
        padding-bottom: 4rem;
    }

    .companies {
        gap: 4rem;
        flex-wrap: wrap;
        position: relative;

        >* {
            max-width: 150px;
            max-height: 100px;
            object-fit: contain;
            z-index: 1;

        }

        &:after {
            display: block;
            width: 150vw;
            height: calc(100% + 2rem);
            z-index: 0;
            position: absolute;
            left: -50vw;
            top: -1rem;
            background: white;
            content: '';
            box-shadow: inset 0 0 10px #666
        }
    }

    @media screen and (max-width: 900px) {
        .companies {
            padding-top: 3rem;

            &:after {
                height: calc(100% + 5rem);
                width: calc(100vw + 3rem);
                left: -3rem;
            }
        }
    }

    @media screen and (max-width: 500px) {
        .companies {
            &:after {
                width: calc(100% + 4rem);
                left: -2rem;
            }
        }
    }

}

.spotlight {
    display: block;
    padding: 5rem;
    text-align: center;
    width: calc(100% - 10rem);
    max-width: 1200px;
    margin: auto;

    &__value-proposition {
        padding-top: 10rem;
    }

    &__summary {
        .summary--container {
            padding-top: 12rem;
;
            border-top: 3px solid $dark-gray;
            margin: 0 auto;
        }
    }

    h3 {
        display: inline-block;
        text-align: left;
        font-size: 3rem;
    }

    button {
        display: block;
        margin-top: 4rem;
    }

    @media screen and (max-width: 1200px) {
        br {
            display: none;
        }
    }

    @media screen and (max-width: 900px) {
        padding: 3rem;
        width: calc(100% - 10rem);

        &__summary .summary--container {
            padding-top: 8rem;
        }

        h3 {
            font-size: 2rem;
        }
    }

    @media screen and (max-width: 500px) {
        width: calc(100% - 4rem);
        padding: 6rem 2rem 2rem 2rem;
    }
}

@keyframes slide-left {
    0% {
        transform: translateX(10vw);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        transform: translateX(-10vw);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}