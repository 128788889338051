footer {
    background: #111;
    text-align: right;
    color: white;
    border-top: 2px solid #333;
    padding: 0 5rem;

    .footer-container {
        max-width: 1200px;
        margin: 0 auto;
        max-height: 15rem;
        display: flex;
        justify-content: space-between;
        padding: 2rem 0;
    }

    .footer__logo {
        max-width: 200px;
    }

    p {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    br {
        display: none;
    }

    @media screen and (max-width: 900px) {
        .footer-container {
            flex-direction: column;
            text-align: center
        }

        .footer__logo {
            margin: 0 auto;
        }

        br {
            display: block;
        }
    }

}