$light-gray: #f5f5f5;
$dark-gray: #545151;
$black: #231930;
$magenta: #ef32d9;
$cyan: #89fffd;
$gradient: linear-gradient(45deg, $magenta, $cyan);

@import './components/HomeSections/HomeSections.scss';
@import './components/footer.scss';
@import './assets/fonts/Satoshi/css/satoshi.css';

body {
    margin: 0;
    padding: 0;
    font-family: 'Satoshi-Variable', sans-serif;
    font-size: 1.5rem;
    box-sizing: border-box;
    overflow-x: hidden;
    overscroll-behavior-y: none;
    background: black;
    color: white;
}

main {
    width: 100vw;
    overflow: hidden;
}

p {
    line-height: 3rem;
}

a {
    font-weight: 400;
    display: inline-block;
    margin-top: 4rem;
    padding-bottom: 0.5rem;
    height: 1.5rem;
    cursor: pointer;
    position: relative;
    color: white;
    text-transform: uppercase;
    font-size: 1rem;

    &:after {
        display: block;
        height: 2px;
        width: 100%;
        background: white;
        content: "";
        position: absolute;
        bottom: -5px;
        transition: all .5s;
    }

    &:hover {
        color: $gradient;

        &:after  {
            bottom: 0;
            background: $gradient;
        }
    }
}

li {
    margin: 0 0 1rem 0;
}

h2 {
    font-family: 'Satoshi-Variable', sans-serif;
    font-size: 3rem;
    font-weight: 400;
    margin: 0 0 3rem 0;
    text-transform: uppercase;
}

h3 {
    color: white;
    font-size: 2rem;
    font-weight: 400;
    margin: 0 auto;
}

input {
    background: none;
    border: none;
}

textarea {
    background: white;
    border: 1px solid $black;
}

section {
    background: black;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 5rem;
}

button {
    // background-color: $gradient;
    color: black;
    outline: none;
    border: none;
    appearance: none;
    padding: 1rem 2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    z-index: 0;

    > span {
        z-index: 2;
        position: relative;
    }

    &:after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        opacity: 0;
        background: $gradient;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity .5s ease;
        z-index: 1;
        cursor: pointer;
    }

    &:hover:after {
        opacity: 1;
    }
    
}

.section {
    &--image {
        width: 100%;
        margin: 2rem 0;
    }

    &--container {
        border-top: 3px solid $dark-gray;
        margin: 6rem 0 4rem 0;
        padding-top: 4rem;
        width: 100%;
        max-width: 1200px;   
    }
}

.container {
    &__flex {
        display: flex;
        justify-content: center;
        gap: 3rem;
        max-width: 100%;

        > * {
            flex: 1;
            margin-top: 0;
        }
    }

    &__centered {
        text-align: center;

        > * {
            margin: auto;
        }
    }

    &__narrow {
        width: 75%;
    }
}

.gradient-text {
    display: inline-block;
    background-image: $gradient;
    background-clip: text;
    color: transparent;
}

@media screen and (max-width: 900px) {
    body {
        font-size: 1rem;
    }

    h2 {
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 2rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    section {
        padding: 0 3rem;
    }

    .container__flex:not(.companies) {
        display: block;
    }
}

@media screen and (max-width: 500px) {
    section {
        padding: 0 2rem;
    }

    .section--container {
        margin: 4rem 0 3rem 0;
        padding-top: 3rem;
    }
}